import React from 'react';
import styled from 'styled-components';
import {useOutlet, getOutlet} from 'reconnect.js';
import {Button, Spin} from 'antd';
import {WIZARD_STEP_KEY} from '../../constants';

function AdminPreview() {
  const [user] = useOutlet('user');
  const [uid, setUid] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);

  const onLoaded = async () => {
    let wizardOutlet = getOutlet('setup-wizard');
    let {steps, curStepIdx, checkAchieve} = wizardOutlet.getValue();
    if (
      steps[curStepIdx]?.key === WIZARD_STEP_KEY.PREVIEW_STORE &&
      !steps[curStepIdx]?.achieve
    ) {
      window.localStorage.setItem(
        `${user.store_id}_wizard_${WIZARD_STEP_KEY.PREVIEW_STORE}`,
        'true',
      );
      await checkAchieve(WIZARD_STEP_KEY.PREVIEW_STORE);
    }
    setLoaded(true);
  };

  return (
    <Wrapper>
      <div className="device">
        <div className="content">
          <div className={`loading${loaded ? '' : ' show'}`}>
            <Spin size="large" tip="Loading..." />
          </div>
          <iframe
            key={uid}
            onLoad={onLoaded}
            src={`/store/preview/?store=${user.store_id}`}
            title="preview"
          />
        </div>
      </div>
      <Button
        style={{position: 'absolute', right: 20, bottom: 20}}
        type="primary"
        size="large"
        onClick={() => setUid(uid + 1)}>
        重新載入
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  & .device {
    position: absolute;
    background-color: black;
    border-radius: 50px;
    border: 3px solid #aaa;
    padding: 60px 12px;

    & .content {
      position: relative;
    }

    & iframe {
      display: block;
      width: 375px;
      height: 667px;
      background-color: white;
    }
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.6);

    & .loading {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
      background-color: #eaeaea;
      opacity: 0;
      transition: opacity 0.3s ease-in;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .loading.show {
      opacity: 1;
    }
  }

  & .device::after {
    content: '';
    background-color: white;
    width: 32px;
    height: 32px;
    border: 2px solid #aaa;
    border-radius: 50%;
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

export default AdminPreview;
